.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: transparent;
  color: #444;
  font-size: 22px;
  line-height: 1;
  content: '\00d7';
}
