.side-panel-component {
  .side-panel-overlay {
    display: none;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

    .side-panel-container {
      display: none;
      background: white;
      position: fixed;
      right: 0;
      top: 0;
      padding: 36px;
      width: 50%;
      min-width: 600px;
      height: 100vh;
    }

    &.active {
      display: block;
      // justify-content: center;
      // align-items: center;

      .side-panel-container {
        display: block;
      }
    }
  }
}
