.modal-component {
  .modal-overlay {
    display: none;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

    &.active {
      display: block;
      // justify-content: center;
      // align-items: center;
    }

    .modal-container {
      background: white;
      position: fixed;
      top: 20%;
      left: 50%;
      transform: translate(-50%, -20%);
      padding: 36px;
      min-width: 480px;
    }
  }
}
